@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&display=swap');

main {
  margin: 0 auto;
  max-width: 90%;
  padding: 50px 30px;
}
:root {
  --primary: #0f0f0f;
  --secondary: #4a8ec3;
  --tertiary: #4a8ec3;
  --light: #e0e0e0;
  --dark: #a0a0a0;
  --darker: rgba(41, 41, 41);
  --alpha: rgba(0, 0, 0, 0.95);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1.2rem);
  --spacing-five: calc(2.5rem);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--dark) var(--primary);
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--dark); 
  border-radius: 5px; 
  border: 2px solid var(--alpha); 
}

*::-webkit-scrollbar-track {
  background: var(--alpha);
  border-radius: 5px;
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 550;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: 0.1em;
  background: var(--alpha);
  color: var(--dark);
}
i {
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 5rem;
  margin: 50px 20px;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

h2 {
  font-size: 2rem;
  color: var(--secondary);
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

h3 {
  font-size: 1.75rem;
  color: var(--secondary);
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-family: "Inter", sans-serif;
  font-size: 1em; 
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: var(--dark);
}

a {
  color: var(--secondary);
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  opacity: 0.5;
}

a:hover {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

header {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
  background: var(--darker);
  color: var(--light);
}

header a {
  color: var(--light);
}

header {
  height: 90px;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

header h1 {
  position: relative;
  z-index: 2;
}

header img {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
}

button {
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0.3rem;
  border: none;
  color: var(--primary);
  outline: none;
  margin-top: 33px;
  background-color: var(--dark);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
  padding: 10px 15px;
  opacity: 0.7;
}

button:hover {
  opacity: 1;
  cursor: pointer;
}

button[disabled] {
  opacity: 0.8;
}

.data-point {
  color: #415a74;
  transition: color 0.3s ease;
  cursor: default;
}

.data-point:hover {
  color: var(--secondary);
}

img {
  max-width: 100%;
}

.image {
  padding-top: 12px;
  padding-bottom: 12px;
}

form {
  width: 40%;
}

label {
  margin-right: 10px;
}

.resume-text li, .blog-text li {
  list-style: none;
  position: relative;
  padding-left: 20px;
}

.resume-text li::before, .blog-text li::before {
  content: "•"; 
  position: absolute;
  left: 0;
  top: 6%;
  color: var(--secondary); 
  font-size: 1.2em; 
  line-height: 1; 
}

.navbar li {
  list-style: none;
  position: static;
  padding-left: 0;
}

.navbar li::before {
  content: none;
}

.about-text,
.resume-text {
  background-color: var(--darker);
  font-family: "Inter", sans-serif;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
  padding: 22px;
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: 0.02em;
  font-weight: 300;
}

.resume-text {
  transition: all 0.5s ease-in-out;
  width: 850px;
}

nav {
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  margin-top: 0%;
  margin-bottom: 0%;
}

nav a:hover {
  border-bottom: 18px solid var(--secondary);
  cursor: pointer; 
}

nav li {
  text-shadow: 2px 2px 10px var(--alpha);
  margin: 0 50px;
  color: var(--light);
  transition: all 0.3s ease-in-out;
}

nav li:hover {
  color: #4a8ec3;
  cursor: pointer; 
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.navActive {
  color: var(--secondary);
  transition: all 0.5s ease-in-out;
  text-shadow: 3px 3px 7px var(--alpha);
}

ul.skills {
  line-height: 2rem;
}

ul.skills li {
  list-style-type: circle;
}

footer {
  text-align: center;
  background-color: transparent;
  font-size: 48px;
  padding-top: 70px;
  height: 60px;
  margin-bottom: 170px;
}

footer a {
  margin-right: 100px;
  margin-left: 100px;
  color: var(--secondary);
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

footer a:hover {
  opacity: 1;
}

textarea {
  border-radius: 1%;
  width: 100%;
  height: 200px;
  border: 2px solid var(--primary);
  padding: 5px;
  margin-top: 10px;
  background-color: var(--dark);
}

textarea:focus {
  font-family: "Inter", sans-serif;
  color: var(--darker);
  border: 1px solid var(--secondary);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
  background-color: var(--light);
  font-size: 1.05rem;
}

input {
  margin: 10px 0;
  font-size: 1rem;
  padding: 0.25rem;
  width: 100%;
  border: 1px solid var(--dark);
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
  outline: none;
  border-radius: 1%;
  transition: box-shadow 0.2s;
  background-color: var(--dark);
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
  background-color: var(--light);
}

.contact {
  font-family: "Inter", sans-serif;
  color: var(--light);
  padding-top: 30px;
  width: 1700px;
  transition: all 0.3s ease-in-out;
}

.contact-item {
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: 0;
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: 0;
}

.px-1 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 30%;
  text-align: center;
  transition: all 1s ease-in-out;
}

.erc721-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  font-family: "Inter", sans-serif;
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: var(--light);
}

.table-header {
  background-color: rgba(65, 90, 116, 0.2);
  color: var(--light);
  font-weight: 400;
  text-align: left;
  padding: 12px;
  white-space: nowrap;
  font-size: 1em;
  line-height: 1.5;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.2);
}

.table-header.token-id-header {
  width: 15%;
}

.table-header.token-uri-header {
  width: 50%;
}

.table-header.image-header,
.table-cell.image-cell {
  width: 25%;
  text-align: center;
  vertical-align: middle;
}

.table-row {
  transition: background-color 0.3s ease-in-out;
}

.table-row:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-row:hover {
  background-color: rgba(74, 142, 195, 0.15);
}

.table-cell {
  padding: 20px 10px 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1em;
  line-height: 1.5;
  color: var(--light);
}

.table-cell.token-id {
  color: var(--secondary);
  font-weight: 400;
  text-shadow: 0 0 4px rgba(65, 90, 116, 0.6);
  max-width: 15%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-cell.token-uri {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-cell.image-cell {
  width: 35%;
  text-align: center;
}

.table-cell img {
  display: block;
  margin: 0 auto;
  vertical-align: middle;
}

.table-link {
  color: var(--tertiary);
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1em;
  line-height: 1.5;
  transition: color 0.3s ease-in-out, text-shadow 0.3s ease-in-out;
}

.table-link:hover {
  color: var(--secondary);
  text-shadow: 0 0 6px rgba(74, 142, 195, 0.4);
}

.nft-image {
  width: 56px;
  height: 70px;
  border-radius: 5px;
  object-fit: cover;
  box-shadow: none;
  transition: transform 0.2s ease-in-out;
}

.nft-image:hover {
  transform: scale(1.05);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid var(--alpha);
  border-top: 4px solid var(--dark);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nft-table-wrapper {
  max-height: calc(4 * 80px + 110px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--dark) var(--alpha);
}

.nft-table-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.nft-table-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--dark);
  border-radius: 5px;
  border: 2px solid var(--alpha); 
}

.nft-table-wrapper::-webkit-scrollbar-track {
  background: var(--alpha);
  border-radius: 5px;
}

.project {
  transition: all 0.5s ease-in-out;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
  position: relative;
  width: 100%;
  max-width: 510px;
  margin-left: 35px;
  margin-right: 35px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.project-bg {
  position: relative;
  width: 100%;
  height: 100%;
}

.project-text {
  font-family: "Inter", sans-serif;
  color: var(--secondary);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 30px;
  z-index: 900;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.project-text:hover {
  transition: all 0.4s ease-in-out;
  background-color: var(--alpha);
  opacity: 0.97;
  border: 3px solid var(--secondary);
}

.project-text h3 {
  font-size: 2.7rem;
}

.project-text p {
  font-size: 1.4em;
  line-height: 38px;
}

.project-text a {
  text-decoration: none;
  font-weight: bold;
  color: var(--light);
  transition: all 0.3s ease-in-out;
}

.project-text a:hover {
  color: var(--tertiary);
}

.scramble {
  width: 455px;
  color: var(--secondary);
  font-family: 'Orbitron', sans-serif;
  font-size: 2rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding-top: 0.28em;
}

.profileImg {
  text-align: center;
  height: 240px;
  width: 240px;
  margin-top: 0;
  transition: filter 0.2s ease-in-out;
  margin-bottom: 0;
}

.profileImg:hover {
  filter: brightness(1.1);
}

.CAcontainer {
  margin-top: 40px;
  overflow: hidden;
  padding-top: -40px;
  width: 850px;
  height: 630px;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.CodingActivity {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0; 
  transition: opacity 0.99s ease-in-out;
}

.CodingActivity.visible {
  background-image: url("https://wakatime.com/share/@532855a8-3081-4600-a53d-4262beb65d14/c677e9c7-0d49-420a-8c6d-2c7c50af9219.svg");
  opacity: 1;
}

.blog-section {
  margin: 0 auto;
  max-width: 80%;
  padding: 40px 20px;
}

.blog-post {
  background-color: var(--darker);
  color: var(--light);
  padding: 20px 30px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
  font-family: "Inter", sans-serif;
}

.blog-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: var(--secondary);
}

.blog-meta {
  font-size: 1rem;
  color: var(--dark);
  margin-bottom: 20px;
}

.blog-date,
.blog-author {
  margin-right: 10px;
}

.blog-content {
  font-size: 1.2rem;
  line-height: 1.8;
  color: var(--light);
}

.blog-cover-image {
  width: 100%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7); 
  margin-bottom: 20px; 
  opacity: 1; 
  transition: opacity 0.5s ease-in-out;
  max-width: 850px;
  display: none;
}

.overlay-text {
  position: absolute;
  top: 10px;
  right: 10px; 
  color: rgb(65, 90, 116); 
  font-size: 1.3rem; 
  font-weight: bold; 
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7); 
  z-index: 2; 
  font-family: "Inter", sans-serif;
}

.dev-icons li {
  display: inline-block;
  list-style-type: none;
  justify-content: center;
  margin-right: 20px;
}

.dev-icons {
  margin-top: 36px;
  margin-bottom: -16px;
  color: var(--Stone);
  font-size: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.dev-icons a {
  font-family: "Saira Extra Condensed", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: var(--Azure);
}

@media (max-width: 1989px) {
  .project {
    transition: all 0.5s ease-in-out;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
    position: relative;
    width: 100%;
    max-width: 700px;
    margin-left: 20px;
    margin-right: 45px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .project-text p {
    font-size: 2.3em;
    line-height: 50px;
  }

  .project-text h3 {
    font-size: 3.5em;
  }

  footer {
    text-align: center;
    background-color: transparent;
    font-size: 50px;
    padding-top: 0px;
    height: 60px;
    margin-bottom: 160px;
  }

  footer a {
    margin-right: 80px;
    margin-left: 80px;
    color: var(--secondary);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 1767px) {
  .project {
    transition: all 0.3s ease-in-out;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
    position: relative;
    width: 100%;
    max-width: 600px;
    margin-left: 20px;
    margin-right: 45px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .project-text p {
    font-size: 1.6em;
    line-height: 42px;
  }

  .project-text h3 {
    font-size: 2.8em;
  }

  footer {
    text-align: center;
    background-color: transparent;
    font-size: 50px;
    padding-top: 0px;
    height: 60px;
    margin-bottom: 140px;
  }

  footer a {
    margin-right: 70px;
    margin-left: 70px;
    color: var(--secondary);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 1545px) {
  .project {
    transition: all 0.3s ease-in-out;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
    position: relative;
    width: 100%;
    max-width: 520px;
    margin-left: 20px;
    margin-right: 45px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .project-text p {
    font-size: 1.4em;
    line-height: 38px;
  }

  .project-text h3 {
    font-size: 2.5em;
  }

  footer {
    text-align: center;
    background-color: transparent;
    font-size: 50px;
    padding-top: 0px;
    height: 60px;
    margin-bottom: 120px;
  }

  footer a {
    margin-right: 70px;
    margin-left: 70px;
    color: var(--secondary);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 1370px) {
  .project {
    transition: all 0.3s ease-in-out;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
    position: relative;
    width: 100%;
    max-width: 450px;
    margin-left: 20px;
    margin-right: 45px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  
  .project-text p {
    font-size: 1.2em;
  }

  .project-text h3 {
    font-size: 2.1em;
  }

  footer {
    text-align: center;
    background-color: transparent;
    font-size: 50px;
    padding-top: 0px;
    height: 60px;
    margin-bottom: 90px;
  }

  footer a {
    margin-right: 70px;
    margin-left: 70px;
    color: var(--secondary);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 1213px) {
  .project {
    transition: all 0.5s ease-in-out;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
    position: relative;
    width: 100%;
    max-width: 950px;
    margin-left: 20px;
    margin-right: 45px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .scramble {
    width: 435px;
  }

  .project-text p {
    line-height: 60px;
    font-size: 1.8em;
  }

  .project h3 {
    font-size: 4em;
  }

  footer {
    text-align: center;
    background-color: transparent;
    font-size: 50px;
    padding-top: 0px;
    height: 60px;
    margin-bottom: 80px;
  }

  footer a {
    margin-right: 70px;
    margin-left: 70px;
    color: var(--secondary);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 1162px) {
  header {
    height: 67px;
    position: relative;
    overflow: hidden;
    z-index: 2;
  }
  nav {
    position: relative;
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    margin-top: 0%;
  }

  nav li {
    text-shadow: 2px 2px 10px var(--alpha);
    margin: 0 50px;
  }

  nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    list-style: none;
  }

  .navActive {
    color: var(--secondary);
    transition: all 0.5s ease-in-out;
    text-shadow: 3px 3px 7px var(--alpha);
  }

  .scramble {
    width: 325px;
  }

  .project-text p {
    line-height: 50px;
    font-size: 1.4em;
  }

  .project h3 {
    font-size: 4em;
  }

  footer {
    text-align: center;
    background-color: transparent;
    font-size: 50px;
    padding-top: 0px;
    height: 60px;
    margin-bottom: 80px;
  }

  footer a {
    margin-right: 70px;
    margin-left: 70px;
    color: var(--secondary);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
  }
}

@media (max-width: 972px) {
  header {
    height: 140px;
    position: relative;
    overflow: hidden;
    z-index: 2;
  }

  nav {
    position: relative;
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    margin-top: 0%;
  }

  nav li {
    text-shadow: 2px 2px 10px var(--alpha);
    margin: 0 50px;
  }

  nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    list-style: none;
  }

  .navActive {
    color: var(--secondary);
    transition: all 0.5s ease-in-out;
    text-shadow: 3px 3px 7px var(--alpha);
  }

  .scramble {
    width: 455px;
    color: var(--secondary);
  }

  .contact {
    width: 1400px;
    padding-top: 10px;
  }

  .CAcontainer {
    width: 630px;
    height: 475px;
  }

  .dev-icons {
    color: var(--Stone);
    padding-left: 0.7vw;
    font-size: 44.2px;
  }

  .resume-text {
    width: 100%;
  }

  .project-text p {
    line-height: 1em;
    font-size: 1.6em;
  }

  .project h3 {
    font-size: 2.5em;
  }

  footer {
    text-align: center;
    background-color: transparent;
    font-size: 50px;
    padding-top: 0px;
    height: 60px;
    margin-bottom: 70px;
  }

  footer a {
    margin-right: 60px;
    margin-left: 60px;
    color: var(--secondary);
    opacity: 0.5;
  }
}

@media (max-width: 726px) {
  body, main, .container, .blog-section, .CAcontainer {
    width: 100%;
    max-width: 100%;
    padding: 10px;
  }

  .CAcontainer {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;
    overflow: hidden; 
  }

  .CodingActivity {
    width: 100%;
    max-width: 850px; 
    height: 100%; 
    min-height: 300px; 
    background-image: url("https://wakatime.com/share/@532855a8-3081-4600-a53d-4262beb65d14/c677e9c7-0d49-420a-8c6d-2c7c50af9219.svg");
    background-size: contain; 
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    opacity: 1; 
  }

  h2 {
    font-size: 1.3rem;
    color: var(--secondary);
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  }
  
  h3 {
    font-size: 1.3rem;
    color: var(--secondary);
  }

  .project, .project-text, .blog-post, .resume-text {
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.8em;
  }

  .table-cell {
    word-wrap: break-word;
    white-space: normal;
  }

  form {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  form button {
    width: 100%;
    max-width: 500px;
    padding: 14px 0;
    font-size: 1rem;
    text-align: center;
    box-sizing: border-box; 
  }

  header {
    height: 140px;
    position: relative;
    overflow: hidden;
    z-index: 2;
  }

  nav {
    text-align: right;
    font-size: 21px;
  }

  nav ul {
    width: 100%;
    text-align: center;
  }

  .mx-2 {
    display: inline-block;
    margin-right: var(--spacing-one);
    margin-left: var(--spacing-one);
  }

  .profileImg {
    text-align: center;
    height: 100%;
    width: 100%;
  }

  .project {
    transition: all 0.5s ease-in-out;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
    position: relative;
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  .contact {
    width: 1180px;
  }

  button {
    width: 465px;
  }

  .dev-icons {
    color: var(--Stone);
    padding-left: 0.7vw;
    font-size: 28px;
  }

  .resume-text {
    width: 100%;
  }

  .project-text p {
    font-size: 1.5em;
    line-height: 37px;
  }

  .project h3 {
    font-size: 2.2em;
  }

  .overlay-text {
    font-size: .8rem; 
  }

  footer {
    text-align: center;
    background-color: transparent;
    font-size: 45px;
    padding-top: 0px;
    height: 60px;
    margin-bottom: 60px;
  }

  footer a {
    margin-right: 50px;
    margin-left: 50px;
    color: var(--secondary);
    opacity: 0.5;
  }
}

@media (max-width: 598px) {
  header {
    height: 140px;
    position: relative;
    overflow: hidden;
    z-index: 2;
  }

  nav {
    text-align: right;
    font-size: 12px;
  }

  nav ul {
    width: 100%;
    text-align: center;
  }

  .profileImg {
    text-align: center;
    height: 100%;
    width: 100%;
  }

  .about-text {
    font-size: 16px;
  }

  .project {
    transition: all 0.5s ease-in-out;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.7);
    position: relative;
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  .contact {
    width: 800px;
  }

  button {
    width: 315px;
  }

  .dev-icons li {
    margin-right: 10px;
  }

  .dev-icons {
    color: var(--Stone);
    padding-left: 0.1vw;
    font-size: 21px;
  }

  .resume-text {
    width: 100%;
  }

  .project-text p {
    font-size: 1.2em;
    line-height: 20px;
  }

  .project-text h3 {
    font-size: 2.3em;
  }

  footer {
    text-align: center;
    background-color: transparent;
    font-size: 45px;
    padding-top: 0px;
    height: 60px;
  }

  footer a {
    margin-right: 20px;
    margin-left: 20px;
    color: var(--secondary);
    opacity: 0.5;
  }
}

@media (max-width: 420px) {

  .resume-text {
    width: 100%;
  }

  nav {
    text-align: center;
    font-size: 0.8em;
    white-space: nowrap;
  }

  nav ul {
    width: max-content;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    overflow-x: auto; 
    white-space: nowrap;
    padding: 0;
    margin: 0 auto;
    list-style: none;
  }

  nav li {
    display: inline-block;
    margin: 0 10px; 
  }

  .contact {
    width: 690px;
  }

  button {
    width: 275px;
  }

  .dev-icons li {
    margin-right: 3px;
  }

  .resume-text {
    width: 100%;
  }

  .dev-icons {
    color: var(--Stone);
    padding-left: 0.1vw;
    font-size: 20.1px;
  }
}

@media (max-width: 380px) {

  .resume-text {
    width: 100%;
  }

  nav {
    font-size: 0.8em;
  }
}

@media (max-width: 350px) {
  .project-text p {
    font-size: 2.8vw;
    line-height: 13px;
  }

  .project-text h3 {
    font-size: .8em;
  }

}